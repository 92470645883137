import qs from 'qs';
import { handleError } from '~/services/error-handling/error-handling';

/* eslint-disable no-param-reassign */
function useCommaArrayFormat(config) {
  config.paramsSerializer = (params) =>
    qs.stringify(params, { arrayFormat: 'repeat' });
}

function useCustomHeaders(config) {
  config.headers.common['X-User-Timezone-Offset'] =
    new Date().getTimezoneOffset();
  config.headers.common['X-Is-Tab-Active'] = process.client
    ? !document.hidden
    : false;
}
function sendToSentryHeaderOfRequestWithAuthError(headers, status) {
  const error = new Error(
    `TheRequestFailedWithAnError. Status: ${status} Headers: ${JSON.stringify(
      headers
    )}`
  );
  handleError(error, { showToast: false });
}
/* eslint-enable no-param-reassign */

export default ({ $axios, store }) => {
  $axios.onRequest((config) => {
    useCommaArrayFormat(config);
    useCustomHeaders(config);
    return config;
  });
  $axios.onResponseError((error) => {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      const { headers } = error.config;
      sendToSentryHeaderOfRequestWithAuthError(headers, error.response.status);
    }
  });
  $axios.onResponse((response) => {
    const { date } = response.headers;
    if (date) {
      store.dispatch('base/setServerDate', date);
    }
  });
};
