import { getLSItem, setLSItem } from '~/services/local-storage';

export const DEFAULT_CALENDAR_TYPE = 'week';
export const CALENDAR_TYPE_LS_KEY = 'timetable_calendar_type_after_redesign';

export const timetablePreferencesPlugin = (store) => {
  if (process.server) return;

  const handleActualPreferences = () =>
    store.dispatch('timetable-preferences/handleActualPreferences');
  window.onNuxtReady(handleActualPreferences);
};

export const state = () => ({
  calendarType: DEFAULT_CALENDAR_TYPE,
});

export const mutations = {
  SET_CALENDAR_TYPE(state, type) {
    state.calendarType = type;
  },
};

export const actions = {
  setCalendarType({ commit }, type) {
    commit('SET_CALENDAR_TYPE', type);
    setLSItem(CALENDAR_TYPE_LS_KEY, type);
  },
  handleActualPreferences({ commit }) {
    const actualType = getLSItem(CALENDAR_TYPE_LS_KEY) || DEFAULT_CALENDAR_TYPE;
    commit('SET_CALENDAR_TYPE', actualType);
  },
};
