import { getAvailableHomeworksForDates } from '~/api/homeworks';
import { formatDate, getDaysBetween } from '~/services/datetime';

export const state = () => ({
  items: [],
  itemsLoading: false,
  lastFetchedCourseId: undefined,
  fetchedRanges: [],
});

export const getters = {
  dateRangeHasBeenFetched:
    (state) =>
    ({ dateFrom, dateTo }) => {
      const rangeDays = getDaysBetween(dateFrom, dateTo);
      return rangeDays.every((rangeDay) =>
        state.fetchedRanges.some(
          (fetchedRange) =>
            new Date(rangeDay) >= new Date(fetchedRange.dateFrom) &&
            new Date(rangeDay) <= new Date(fetchedRange.dateTo)
        )
      );
    },
  itemsByDate: (state) => {
    const itemsByDate = {};
    state.items.forEach((item) => {
      const itemDate = formatDate({
        value: item.content.deadlineAt,
        format: 'YYYY-MM-DD',
      });
      itemsByDate[itemDate] = itemsByDate[itemDate] || [];
      itemsByDate[itemDate].push(item);
    });
    return itemsByDate;
  },
};

export const mutations = {
  PUT_HOMEWORK_ITEM(state, homeworkItem) {
    const existingIndex = state.items.findIndex(
      (item) => item.id === homeworkItem.id
    );
    if (existingIndex === -1) {
      state.items.push(homeworkItem);
    }
  },
  PUT_FETCHED_RANGE(state, { dateFrom, dateTo }) {
    state.fetchedRanges.push({
      dateFrom: new Date(dateFrom),
      dateTo: new Date(dateTo),
    });
  },
  SET_ITEMS_LOADING(state, value) {
    state.itemsLoading = !!value;
  },
  SET_LAST_FETCHED_COURSE_ID(state, courseId) {
    state.lastFetchedCourseId = courseId;
  },
  RESET_FETCHED_DATA(state) {
    state.items = [];
    state.lastFetchedCourseId = undefined;
    state.fetchedRanges = [];
  },
};

export const actions = {
  async fetchHomeworkItems(
    { state, getters, commit },
    { dateFrom, dateTo, courseId, archived = false }
  ) {
    if (state.lastFetchedCourseId !== courseId) {
      commit('RESET_FETCHED_DATA');
    }
    if (getters.dateRangeHasBeenFetched({ dateFrom, dateTo })) return;
    commit('SET_ITEMS_LOADING', true);
    const availableHomeworks = await getAvailableHomeworksForDates({
      dateFrom,
      dateTo,
      coursesIds: [courseId],
      archived,
    });
    commit('PUT_FETCHED_RANGE', { dateFrom, dateTo });
    commit('SET_LAST_FETCHED_COURSE_ID', courseId);
    availableHomeworks.forEach((homeworkItem) => {
      commit('PUT_HOMEWORK_ITEM', homeworkItem);
    });
    commit('SET_ITEMS_LOADING', false);
  },
};
