import pick from 'lodash/pick';
import { INITIAL_TASK_SOLUTION } from '~/constants/task-solution';
import { isRequired } from '~/utils/is-required';
import { formatDate } from '~/services/datetime';
import { http } from '~/services/http';

export async function getHomework({ courseId, homeworkId }) {
  const url = `/api/v2/student/courses/${courseId}/homework/${homeworkId}/`;

  const response = await http.get(url);
  return response;
}

export async function getHomeworkList({
  courseId = isRequired(),
  completed,
  page = 1,
  pageSize = 20,
}) {
  const params = {
    page,
    page_size: pageSize,
    ...(completed !== undefined ? { completed } : {}),
  };
  const url = `/api/v2/student/courses/${courseId}/homework/`;

  const { results, count, next } = await http.get(url, { params });
  return {
    results,
    totalCount: count,
    hasNextPage: !!next,
  };
}

export async function getTasks({ courseId, homeworkId }) {
  const url = `/api/v2/student/courses/${courseId}/homework/${homeworkId}/tasks/`;

  const response = await http.get(url);
  return response;
}

export async function getReviewResult({ courseId, homeworkId }) {
  const url = `/api/v2/student/courses/${courseId}/homework/${homeworkId}/review-result/`;

  const response = await http.get(url);
  return response;
}

export async function startHomework({ courseId, homeworkId }) {
  const url = `/api/v2/student/courses/${courseId}/homework/${homeworkId}/start/`;

  const response = await http.post(url);
  return response;
}

export async function finishHomework({ courseId, homeworkId }) {
  const url = `/api/v2/student/courses/${courseId}/homework/${homeworkId}/finish/`;

  const response = await http.post(url);
  return response;
}

export async function cancelHomework({ courseId, homeworkId }) {
  const url = `/api/v2/student/courses/${courseId}/homework/${homeworkId}/cancel/`;

  const response = await http.post(url);
  return response;
}

export async function addSolution({ courseId, homeworkId, taskId }, params) {
  const url = `/api/v2/student/courses/${courseId}/homework/${homeworkId}/tasks/${taskId}/add-solution/`;
  const allowedParamsKeys = Object.keys(INITIAL_TASK_SOLUTION);
  const allowedParams = pick(params, allowedParamsKeys);

  const response = await http.post(url, allowedParams);
  return response;
}

export async function reportError(
  { courseId = isRequired(), taskId = isRequired() },
  params
) {
  const url = `/api/v2/student/courses/${courseId}/tasks/${taskId}/report-error/`;

  const response = await http.post(url, params);
  return response;
}

export async function addReview({ courseId, homeworkId, taskId }, params) {
  const url = `/api/v2/student/courses/${courseId}/homework/${homeworkId}/tasks/${taskId}/add-review/`;

  const response = await http.post(url, params);
  return response;
}

export async function addSolutionAttachment(formData) {
  const url = `/api/v2/student/task-solution-attachments/`;

  const response = await http.post(url, formData);
  return response;
}

export function getHomeworkStatistics({
  courseId = isRequired(),
  homeworkId = isRequired(),
}) {
  return http.get(
    `/api/v2/student/courses/${courseId}/homework/${homeworkId}/statistics/`
  );
}

export function updateHomeworkStatisticsConfig({
  isHidden = isRequired(),
} = {}) {
  return http.post(`/api/v2/student/homework-statistics-config/`, {
    isHidden,
  });
}

export function getIsCouldBeShownConsultationInvitation({
  courseId = isRequired(),
  homeworkId = isRequired(),
}) {
  return http.get(
    `/api/v2/student/courses/${courseId}/homework/${homeworkId}/could-be-shown-consultation-invitation/`
  );
}

export function showConsultationInvitation({
  courseId = isRequired(),
  homeworkId = isRequired(),
}) {
  return http.post(
    `/api/v2/student/courses/${courseId}/homework/${homeworkId}/show-consultation-invitation/`
  );
}

export function getAvailableHomeworksForDates({
  dateFrom = isRequired(),
  dateTo = isRequired(),
  coursesIds,
  archived = false,
} = {}) {
  const url = '/api/v2/student/available-homeworks/';
  return http.get(url, {
    params: {
      deadline_date__gte: formatDate({ value: dateFrom, format: 'YYYY-MM-DD' }),
      deadline_date__lte: formatDate({ value: dateTo, format: 'YYYY-MM-DD' }),
      courses: coursesIds,
      allow_archived: archived,
    },
  });
}
