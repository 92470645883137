<script>
import { WButton, WDrawer, WModal } from '@WebiumTeam/ui_kit';
import kebabCase from 'lodash/kebabCase';
import { createNamespacedHelpers } from 'vuex';
import { ONBOARDING_STEPS } from '~/constants/onboarding';

const { mapState: mapCurrentCourseState } =
  createNamespacedHelpers('current-course');

export const messages = {
  ru: {
    defaultTitle: 'привет! давай настроим твой курс',
    continue: 'продолжить',
    finish: 'завершить',
    finishSubmit: 'всё понятно!',
    doLater: {
      0: 'выбрать позже',
    },
  },
};

const cachedStepComponents = {};

export default {
  components: {
    WButton,
  },
  i18n: {
    messages,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    initialStepKey: {
      type: String,
      default: '',
      required: false,
      validator: (value) =>
        !value || Object.values(ONBOARDING_STEPS).includes(value),
    },
  },
  data() {
    return {
      currentTitle: this.$t('defaultTitle'),
      groupedSteps: [],
      currentStepGlobalIndex: 0,
    };
  },
  computed: {
    ...mapCurrentCourseState(['currentCourse']),
    currentStepKey() {
      return this.groupedSteps.flat()[this.currentStepGlobalIndex];
    },
    nextStepKey() {
      return this.groupedSteps.flat()[this.currentStepGlobalIndex + 1];
    },
    isLastStep() {
      return this.currentStepGlobalIndex + 1 >= this.groupedSteps.flat().length;
    },
    currentStepComponent() {
      if (cachedStepComponents[this.currentStepKey])
        return cachedStepComponents[this.currentStepKey];
      const componentName = `step-${kebabCase(this.currentStepKey)}`;
      cachedStepComponents[this.currentStepKey] = () =>
        import(`./steps/${componentName}.vue`);
      return cachedStepComponents[this.currentStepKey];
    },
    showFinishButton() {
      return (
        this.isCompleted ||
        this.currentStepGlobalIndex >= this.groupedSteps.flat().length - 1
      );
    },
    progressSegments() {
      return this.groupedSteps.map((groupItems) => groupItems.length);
    },
    mobileCloseButtonText() {
      if (!this.$device.isSmallScreen) return '';
      const doLaterText =
        messages[this.$i18n.locale].doLater[this.currentStepGlobalIndex];
      return this.showFinishButton ? this.$t('finish') : doLaterText;
    },
    modalComponent() {
      return this.$device.isSmallScreen ? WDrawer : WModal;
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(visible) {
        if (visible) {
          this.resolveGroupedSteps();
          this.resolveCurrentGlobalIndex();
        }
      },
    },
    currentStepKey: {
      handler(stepKey) {
        this.$emit('step-change', stepKey);
      },
    },
  },
  methods: {
    resolveCurrentGlobalIndex() {
      if (!this.initialStepKey) return;
      const currentStepGlobalIndex = this.groupedSteps
        .flat()
        .indexOf(this.initialStepKey);
      this.currentStepGlobalIndex =
        currentStepGlobalIndex === -1 ? 0 : currentStepGlobalIndex;
    },
    resolveGroupedSteps() {
      if (this.isCompleted) {
        this.groupedSteps = [
          this.currentCourse.onboardingConfig.welcomePopupText
            ? ONBOARDING_STEPS.courseInfo
            : undefined,
        ];
        return;
      }
      const hasCourseGoals = (this.currentCourse.courseGoals || []).length > 0;
      const groupedSteps = [
        [
          ...(hasCourseGoals && !this.currentCourse.learningGoal?.goal
            ? [ONBOARDING_STEPS.goals, ONBOARDING_STEPS.goals]
            : []),
        ],
        [
          this.currentCourse.onboardingConfig?.welcomePopupText
            ? ONBOARDING_STEPS.courseInfo
            : undefined,
        ],
      ];
      this.groupedSteps = groupedSteps
        .map((group) => group.filter((step) => !!step))
        .filter((group) => group && group.length > 0);
    },
    close() {
      this.$emit('close');
    },
    finish() {
      if (this.isCompleted) {
        this.$emit('close');
        return;
      }
      this.$emit('finish');
    },
    onUpdateTitle(title) {
      this.currentTitle = title;
    },
    onFinish() {
      this.finish();
    },
    onNextStep() {
      if (this.isLastStep) {
        this.finish();
      } else {
        this.currentStepGlobalIndex += 1;
      }
    },
    onPreviousStep() {
      if (this.currentStepGlobalIndex === 0) return;
      this.currentStepGlobalIndex -= 1;
    },
    onDoLater() {
      this.close();
    },
    onCloseClick() {
      return this.showFinishButton ? this.onFinish() : this.onDoLater();
    },
  },
};
</script>

<template>
  <component
    :is="modalComponent"
    :visible="visible"
    :side="$device.isSmallScreen ? 'bottom' : ''"
    :class="$style.modal"
    rounded
    hide-header
    hide-close
    size="m"
    @close="close"
  >
    <WButton
      v-if="mobileCloseButtonText"
      theme="transparent"
      data-testid="external-button"
      :size="$device.isSmallScreen ? 's' : 'm'"
      :class="$style.externalButton"
      @click="onCloseClick"
    >
      {{ mobileCloseButtonText }}
    </WButton>
    <div :class="$style.inner">
      <strong :class="$style.title">{{ currentTitle }}</strong>
      <div>
        <component
          :is="currentStepComponent"
          v-if="currentStepKey"
          :next-step-key="nextStepKey"
          @next-step="onNextStep"
          @previous-step="onPreviousStep"
          @update-title="onUpdateTitle"
          @do-later="onDoLater"
        />
      </div>
    </div>
  </component>
</template>

<style lang="postcss" module>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 34px;
}

.externalButton {
  position: absolute;
  top: -49px;
  right: 13px;
}

@media (--small-vp) {
  .modal {
    --w-drawer-side-gap: 64px;
    --w-drawer-height: 100%;
    --w-drawer-overflow-y: auto;
  }

  .externalButton {
    position: fixed;
    top: 16px;
  }

  .inner {
    --modal-padding-x: 20px;

    padding: 20px var(--modal-padding-x) 20px;
  }

  .title {
    margin-right: 0;
  }
}
</style>
