import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/all-student-courses-preview.js'), 'all-student-courses-preview.js')
  resolveStoreModules(require('../store/all-student-courses.js'), 'all-student-courses.js')
  resolveStoreModules(require('../store/banners.js'), 'banners.js')
  resolveStoreModules(require('../store/base.js'), 'base.js')
  resolveStoreModules(require('../store/common-onboarding.js'), 'common-onboarding.js')
  resolveStoreModules(require('../store/course-content.js'), 'course-content.js')
  resolveStoreModules(require('../store/current-course.js'), 'current-course.js')
  resolveStoreModules(require('../store/feature-toggles.js'), 'feature-toggles.js')
  resolveStoreModules(require('../store/free-course-onboarding.js'), 'free-course-onboarding.js')
  resolveStoreModules(require('../store/homeworks-with-deadlines.js'), 'homeworks-with-deadlines.js')
  resolveStoreModules(require('../store/incompleted-homework.js'), 'incompleted-homework.js')
  resolveStoreModules(require('../store/mentors-satisfaction-modal.js'), 'mentors-satisfaction-modal.js')
  resolveStoreModules(require('../store/modals.js'), 'modals.js')
  resolveStoreModules(require('../store/motivation-achievements.js'), 'motivation-achievements.js')
  resolveStoreModules(require('../store/motivation-actions.js'), 'motivation-actions.js')
  resolveStoreModules(require('../store/motivation-gifts.js'), 'motivation-gifts.js')
  resolveStoreModules(require('../store/motivation-levels.js'), 'motivation-levels.js')
  resolveStoreModules(require('../store/motivation.js'), 'motivation.js')
  resolveStoreModules(require('../store/notification-list.js'), 'notification-list.js')
  resolveStoreModules(require('../store/notification-modal.js'), 'notification-modal.js')
  resolveStoreModules(require('../store/notification-settings.js'), 'notification-settings.js')
  resolveStoreModules(require('../store/nps-modal.js'), 'nps-modal.js')
  resolveStoreModules(require('../store/onboarding-survey.js'), 'onboarding-survey.js')
  resolveStoreModules(require('../store/responsive.js'), 'responsive.js')
  resolveStoreModules(require('../store/streaks-history.js'), 'streaks-history.js')
  resolveStoreModules(require('../store/streaks.js'), 'streaks.js')
  resolveStoreModules(require('../store/student-courses-archive.js'), 'student-courses-archive.js')
  resolveStoreModules(require('../store/student-courses-next-lessons.js'), 'student-courses-next-lessons.js')
  resolveStoreModules(require('../store/student-courses.js'), 'student-courses.js')
  resolveStoreModules(require('../store/student-greeting-survey-modal.js'), 'student-greeting-survey-modal.js')
  resolveStoreModules(require('../store/system-notifications.js'), 'system-notifications.js')
  resolveStoreModules(require('../store/timetable-preferences.js'), 'timetable-preferences.js')
  resolveStoreModules(require('../store/timetable.js'), 'timetable.js')
  resolveStoreModules(require('../store/tooltips.js'), 'tooltips.js')
  resolveStoreModules(require('../store/webinars.js'), 'webinars.js')
  resolveStoreModules(require('../store/workout-kim-numbers.js'), 'workout-kim-numbers.js')
  resolveStoreModules(require('../store/workout-themes.js'), 'workout-themes.js')
  resolveStoreModules(require('../store/workout.js'), 'workout.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
