import { isRequired } from '~/utils/is-required';
import { http } from '~/services/http';

export function getSurveysOverview() {
  const url = `/api/v2/student/surveys/overview/`;
  const response = http.get(url);
  return response;
}

export async function setSurveysGreetingAnswer(payload) {
  const requestUrl = `/api/v2/student/surveys/greeting/answer/`;

  const response = await http.post(requestUrl, payload);
  return response;
}

export const addSatisfaction = async (
  id,
  { satisfaction, reasons, anotherReason }
) => {
  const url = `/api/v2/student/surveys/lesson-content-items/${id}/add-satisfaction/`;

  const response = await http.post(url, {
    satisfaction,
    reasons,
    anotherReason,
  });
  return response;
};

export const checkSatisfaction = async (id) => {
  const url = `/api/v2/student/surveys/lesson-content-items/${id}/check-satisfaction/`;

  const response = await http.get(url);
  return response;
};

export const getMentorsForSatisfaction = ({ ignoreClosures = false } = {}) => {
  const url = `/api/v2/student/surveys/mentors-for-satisfaction/`;

  return http.get(url, {
    params: {
      ignore_closures: !!ignoreClosures,
    },
  });
};

export const addMentorsSatisfaction = (payload) => {
  const url = `/api/v2/student/surveys/add-mentors-satisfaction/`;

  return http.post(url, [payload].flat());
};

export const checkExplanationSatisfaction = async (id) => {
  const url = `/api/v2/student/surveys/task-explanation-satisfaction/${id}/`;

  const response = await http.get(url);
  return response;
};

export const addExplanationSatisfaction = async (id, payload) => {
  const url = `/api/v2/student/surveys/task-explanation-satisfaction/${id}/`;

  const response = await http.post(url, payload);
  return response;
};

export const getIsNpsCouldBeShown = async () => {
  const url = `/api/v2/student/surveys/nps/could-be-shown/`;

  const response = await http.get(url);
  return !!response?.couldBeShown;
};

export const addNpsAnswer = async ({ score = isRequired(), description }) => {
  const url = `/api/v2/student/surveys/nps/answer/`;

  const response = await http.post(url, {
    score,
    description: description || '',
  });
  return response;
};

export const saveNpsClose = async () => {
  const url = `/api/v2/student/surveys/nps/close/`;

  const response = await http.post(url);
  return response;
};

export const getOnboardingQuestions = async () => {
  const url = '/api/v2/student/surveys/onboarding-questions/';

  const response = await http.get(url);
  return response;
};

export const addOnboardingQuestionsAnswer = async (questionId, answer) => {
  const url = `/api/v2/student/surveys/onboarding-questions/${questionId}/answer/`;

  const response = await http.post(url, {
    answer,
  });
  return response;
};

export const getTypeforms = async ({ coursesIds } = {}) => {
  const url = `/api/v2/student/surveys/typeforms/`;

  const response = await http.get(url, {
    params: {
      courses: coursesIds,
    },
  });

  return response;
};

export const saveTypeformAnswer = async (typeformId = isRequired()) => {
  const url = `/api/v2/student/surveys/typeforms/${typeformId}/submit/`;

  const response = await http.post(url);
  return response;
};

export const changeMentor = ({
  mentorId = isRequired(),
  courseId = isRequired(),
  changeReason = isRequired(),
  newMentorWishes = isRequired(),
}) => {
  const url = `/api/v2/student/surveys/change-mentor/`;

  return http.post(url, {
    mentorId,
    courseId,
    changeReason,
    newMentorWishes,
  });
};

export const getIsSurveyWasAnswered = async (surveySlug = isRequired()) => {
  const url = `/api/v2/student/surveys/${surveySlug}/`;

  const response = await http.get(url);
  return !!response?.wasAnswered;
};

export const addSurveyAnswer = async ({
  surveySlug = isRequired(),
  answersData,
}) => {
  const url = `/api/v2/student/surveys/${surveySlug}/`;

  const response = await http.post(url, {
    // eslint-disable-next-line unicorn/no-null
    answers: answersData || null,
  });
  return response;
};
