// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".course-onboarding-modal__inner-gXaKK{position:relative;display:flex;flex-direction:column}.course-onboarding-modal__title-jv3f3{margin-right:16px;margin-bottom:16px;font-size:28px;line-height:34px}.course-onboarding-modal__externalButton-A8v7d{position:absolute;top:-49px;right:13px}@media (max-width:1023px){.course-onboarding-modal__modal-lWUEa{--w-drawer-side-gap:64px;--w-drawer-height:100%;--w-drawer-overflow-y:auto}.course-onboarding-modal__externalButton-A8v7d{position:fixed;top:16px}.course-onboarding-modal__inner-gXaKK{--modal-padding-x:20px;padding:20px var(--modal-padding-x) 20px}.course-onboarding-modal__title-jv3f3{margin-right:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "course-onboarding-modal__inner-gXaKK",
	"title": "course-onboarding-modal__title-jv3f3",
	"externalButton": "course-onboarding-modal__externalButton-A8v7d",
	"modal": "course-onboarding-modal__modal-lWUEa"
};
module.exports = ___CSS_LOADER_EXPORT___;
