<script>
import { WButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { MODALS } from '~/constants/modals';
import { ONBOARDING_STEPS } from '~/constants/onboarding';
import BaseSegmentedProgress from '~/components/_base/_base-segmented-progress.vue';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import XpSign from '~/components/xp-sign.vue';

const { mapActions: mapModalsActions } = createNamespacedHelpers('modals');

const { mapState, mapGetters } = createNamespacedHelpers('common-onboarding');

export const messages = {
  ru: {
    experience: 'опыт',
    title: 'настрой своё обучение',
    tooltip: '{xpSign} будет начислен после прохождения всего онбординга',
    caption: {
      [ONBOARDING_STEPS.vkConnect]: 'подключи уведомления',
      [ONBOARDING_STEPS.notifications]: 'подключи уведомления',
      [ONBOARDING_STEPS.survey]: 'выбери стиль обучения',
    },
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    BasePopover,
    WButton,
    BaseSegmentedProgress,
    XpSign,
  },
  data() {
    return {
      initialCompletedStepsCount: 0,
    };
  },
  computed: {
    ...mapState(['isHidden', 'isInitialized', 'initialCompletedStepIndexes']),
    ...mapGetters(['allSegments', 'isAllStepsCompleted']),
    isVisible() {
      if (this.isAllStepsCompleted) return false;
      if (!this.isInitialized) return false;
      return !this.isHidden;
    },
    allSteps() {
      return this.allSegments.flat();
    },
    progressSegments() {
      const visibleStepsCount =
        this.allSteps.length - this.initialCompletedStepsCount;
      return Array.from({ length: visibleStepsCount }, () => 1);
    },
    completedStepsCount() {
      const totalCompletedStepsCount = this.allSteps.filter(
        (step) => step.isCompleted
      ).length;
      return totalCompletedStepsCount - this.initialCompletedStepsCount;
    },
    firstNotCompletedStepKey() {
      return this.allSteps.find((step) => !step.isCompleted)?.key;
    },
    caption() {
      return this.$t(`caption.${this.firstNotCompletedStepKey}`);
    },
  },
  watch: {
    initialCompletedStepIndexes: {
      immediate: true,
      handler(value) {
        this.setInitialCompletedStepsCount((value || []).length);
      },
    },
  },
  methods: {
    ...mapModalsActions(['setVisibleModalNameIfPriorityIsHigher']),
    setInitialCompletedStepsCount(value) {
      if (this.initialCompletedStepsCount) return;
      this.initialCompletedStepsCount = value;
    },
    onClick() {
      this.setVisibleModalNameIfPriorityIsHigher(MODALS.commonOnboarding);
    },
    onTooltipAnchorClick(event) {
      if (this.$device.isSmallScreen) {
        event.stopPropagation();
      }
    },
  },
};
</script>

<template>
  <Transition>
    <WButton
      v-if="isVisible"
      :class="$style.root"
      theme="tertiary"
      @click="onClick"
    >
      <span :class="$style.title">{{ $t('title') }}</span>
      <BaseSegmentedProgress
        :class="$style.progress"
        :segments="progressSegments"
        :value="completedStepsCount"
        size="m"
      />
      <div :class="$style.footer">
        <span :class="$style.caption">{{ caption }}</span>
        <BasePopover
          :class="$style.popover"
          :popper-class="$style.popper"
          placement="bottom"
          :trigger="['hover', 'click']"
          show-arrow
        >
          <XpSign
            size="s"
            text="+30"
            clickable
            @click.native="onTooltipAnchorClick"
          />
          <template #dropdown>
            <div :class="$style.popoverContent">
              <i18n path="tooltip" tag="p">
                <template #xpSign>
                  <XpSign
                    size="s"
                    :class="$style.xpSignInPopover"
                    :text="$t('experience')"
                  />
                </template>
              </i18n>
            </div>
          </template>
        </BasePopover>
      </div>
    </WButton>
  </Transition>
</template>

<style lang="postcss" module>
.root {
  --base-button-padding: 8px 12px 10px;

  display: flex;
  flex-direction: column;
  gap: 0;
}

.title {
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;
}

.progress {
  margin-top: 8px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin-top: 12px;
}

.caption {
  font-size: 14px;
  line-height: 18px;
  white-space: pre-line;
}

.popper {
  width: 242px;
  margin-top: 4px;
}

.popoverContent {
  padding: 12px 18px;
  font-size: 14px;
  line-height: 18px;
}

.xpSignInPopover {
  margin: -3px 2px 0 0;
}

.popover {
  align-self: flex-start;
}

@media (--small-vp) {
  .root {
    min-height: 90px;
  }
}
</style>
